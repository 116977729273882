/*============================================
Contact Map
==============================================*/
    function loadGoogleMap() {
        "use strict";
    
        // Set mapPoint, latitude and longitude, zoom, and other info needed for Google Map
        var mapPoint = {
                    'lat': 50.70386845989554,
                    'lng': 10.85134311877141,
                    'zoom' : 17,
                    'infoText':'Schloss Elgersburg',
                    'linkText':'Route berechnen',
                    'mapAddress':'Schloss Elgersburg'
                };

        if(jQuery('#homepage_map').length){
        
            var map;
            var mapstyles = [ { "stylers": [ { "saturation": -100 } ] } ];
            
            var infoWindow = new google.maps.InfoWindow();
            var pointLatLng = new google.maps.LatLng(mapPoint.lat, mapPoint.lng);

            // Define options for the Google Map
            var mapOptions = {
                zoom: mapPoint.zoom,
                center: pointLatLng,
                zoomControl : true,
                panControl : false,
                streetViewControl : false,
                mapTypeControl: false,
                overviewMapControl: false,
                scrollwheel: false,
                styles: mapstyles
            };
            
            // Create new Google Map object for pop-up restaurant windows
            // map = new google.maps.Map(document.getElementById("restaurant_map"), mapOptions);
            
            // Create new Google Map object for full width map section on homepage
            map = new google.maps.Map(document.getElementById("homepage_map"), mapOptions);

            var marker = new google.maps.Marker({
                position: pointLatLng, 
                map: map, 
                title:mapPoint.linkText,
                icon: mapPoint.icon
            });
            
            var mapLink = 'https://www.google.com/maps/preview?ll='+mapPoint.lat+','+mapPoint.lng+'&z=14&q='+mapPoint.mapAddress;
            
            // Set the info window content
            var html = '<div class="infowin">' + mapPoint.infoText + '<a href="'+mapLink+'" target="_blank">'+mapPoint.linkText+'</a>' + '</div>';

            // Add map marker
            google.maps.event.addListener(marker, 'mouseover', function() {
                infoWindow.setContent(html);
                infoWindow.open(map, marker);
            });

            // Function for when the map marker is clicked 
            google.maps.event.addListener(marker, 'click', function() {
                window.open(mapLink,'_blank');
            });
            
        }
    }

// /*=============================================
// Booking Form
// ==============================================*/

// jQuery(function($) {
//     "use strict";
//     loadGoogleMap();
//     if ( jQuery('#video').length !== 0 ) {
//         // Pause video before the page is ready
//         jQuery('#video').get(0).pause();
//     }
//     // use jQuery Bootstrap Validation to validate the booking form input
//     jQuery("input,textarea").jqBootstrapValidation({
//         preventSubmit: true,
//         submitError: function($form, event, errors) {
//             // additional error messages or events
//         },
//         submitSuccess: function($form, event) {
//             event.preventDefault(); // prevent default submit behaviour
//             // get values from FORM
//             var name            = jQuery("input#first_name1").val() + ' ' + jQuery("input#last_name1").val();
//             var email           = jQuery("input#email1").val();
//             var phone           = jQuery("input#phone1").val();
//             var reservDate      = jQuery('input#reserv_date1').val();
//             var numGuests       = jQuery('input#numb_guests1').val();
//             var altDate         = jQuery('input#alt_reserv_date1').val();
//             var bookingTime     = jQuery('input#time1').val();
//             var message         = jQuery("textarea#message").val();
//             $.ajax({
//                 url: "././assets/php/mail/booking.php",
//                 type: "POST",
//                 data: {
//                     name: name,
//                     phone: phone,
//                     email: email,
//                     reservDate: reservDate,
//                     numGuests: numGuests,
//                     altDate: altDate,
//                     bookingTime: bookingTime,
//                     message: message
//                 },
//                 cache: false,
//                 success: function() {
//                     // Success message
//                     $('#success').html("<div class='alert alert-success'>");
//                     $('#success > .alert-success').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
//                         .append("</button>");
//                     $('#success > .alert-success')
//                         .append("<strong>Your booking has been submitted. </strong>");
//                     $('#success > .alert-success')
//                         .append('</div>');

//                     //clear all fields
//                     $('#contactForm').trigger("reset");
//                 },
//                 error: function() {
//                     // Fail message
//                     $('#success').html("<div class='alert alert-danger'>");
//                     $('#success > .alert-danger').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
//                         .append("</button>");
//                     $('#success > .alert-danger').append("<strong>Sorry, the mail server is not responding. Please try again later!");
//                     $('#success > .alert-danger').append('</div>');
//                     //clear all fields
//                     $('#contactForm').trigger("reset");
//                 },
//             });
//         },
//         filter: function() {
//             return $(this).is(":visible");
//         },
//     });
// });

/*============================================
Match height of header carousel to window height
==============================================*/
function matchCarouselHeight() {
    "use strict";
    // Adjust Header carousel .item height to same as window height
    var wH = jQuery(window).height();
    jQuery('#hero-carousel .item').css("height", wH);
}

/*====================================================================================================
Any JS inside $(window).load function is called when the window is ready and all assets are downloaded
======================================================================================================*/
jQuery(window).load(function($) {
    "use strict";

    loadGoogleMap();

    // Remove loading screen when window is loaded after 1.5 seconds
    setTimeout(function() {
        jQuery(window).trigger('resize');
        jQuery('.loading-screen').fadeOut(); // fade out the loading-screen div
        if ( jQuery('#video').length !== 0 ) {
            // Play video once the page is fully loaded and loading screen is hidden
            jQuery('#video').get(0).play();
        }
    },1500); // 1.5 second delay so that we avoid the 'flicker' of the loading screen showing for a split second and then hiding immediately when its not needed

    // Call function for Google Maps
    jQuery('.restaurantPopUp').on('show.bs.modal', function (e) {
        // Call function for Google Maps when a modal is opened
        setTimeout(function() {
            loadGoogleMap();
        },300);   
    });

});

/*==================================================
Any JS inside $(window).resize(function() runs when the window is resized
====================================================*/

jQuery(window).resize(function($) {
    "use strict";
    // Call the matchCarouselheight() function when the window is resized
    matchCarouselHeight();
});

/*==================================================
Any JS inside $(window).scroll(function() runs when the window is scrolled
====================================================*/

jQuery(window).scroll(function($) {
    "use strict";
    if (jQuery(this).scrollTop() > 100) {
        jQuery('.scroll-up').fadeIn();
    } else {
        jQuery('.scroll-up').fadeOut();
    }
});

/*==================================================
Any JS inside jQuery(function($) runs when jQuery is ready
====================================================*/
jQuery(function($) {
    "use strict";
    // We use strict mode to encounter errors when using JSHint/JSLint

    // Call matchCarouselHeight() function
    matchCarouselHeight();

    //Highlight the top nav as scrolling occurs
    jQuery('body').scrollspy({
        target: '.navbar-shrink',
        offset: 0
    });

    // Smooth scrolling links - requires jQuery Easing plugin
    jQuery('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);

        if ( $anchor.hasClass('header-scroll') ) {
            jQuery('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top
            }, 1500, 'easeInOutExpo');
        }
        else{
            jQuery('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top - 75
            }, 1500, 'easeInOutExpo');
        }
        event.preventDefault();
    });

    // Call the matchCarouselHeight() function when the carousel slide.bs event is triggered
    jQuery('#hero-carousel').on('slide.bs.carousel', function () {
        matchCarouselHeight();
    });

    // Initialise WOW.js for section animation triggered when page scrolling
    new WOW().init();
});
